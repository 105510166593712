export const getAgents = () => {
  return [
    {
      id: 1,
      agent: {
        id: 1,
        agent_name: "Architect",
      },
      squads: ["OP1", "BIM Leader"],
      users: [
        {
          id: 1,
          first_name: "Sophia",
          last_name: "Holland",
          email: "sholland@imarch.uk",
          avatar: require("@/assets/temp/users/sophia_holland.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 1,
            company_name: "IM Architecture",
            email: "helpdesk@imarch.uk",
            logo: require("@/assets/temp/orgs/imarch.png"),
          },
        },
        {
          id: 30,
          first_name: "Dorothy",
          last_name: "Corey",
          email: "dcorey@imarch.uk",
          avatar: "",
          in_stages: [4], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 10,
            company_name: "Gradient",
            email: "support@gradient.com",
            logo: require("@/assets/temp/orgs/gradient.png"),
          },
        },
      ],

      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 2,
      agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      squads: ["OP3", "Product"],
      users: [
        {
          id: 2,
          first_name: "Travis",
          last_name: "Chambers",
          email: "tchambers@bm.net",
          avatar: require("@/assets/temp/users/travis_chambers.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 2,
            company_name: "B&M",
            email: "management@bm.net",
            logo: require("@/assets/temp/orgs/bm.png"),
          },
        },
        {
          id: 3,
          first_name: "Elizabeth",
          last_name: "Rogers",
          email: "elirogers@bm.com",
          avatar: "https://rb.gy/z11ou",
          in_stages: [4, 5, 6], //frame_stage_id's
          role: { title: "Coordinator" },
          company: {
            id: 2,
            company_name: "B&M",
            email: "management@bm.net",
            logo: require("@/assets/temp/orgs/bm.png"),
          },
        },
        {
          id: 4,
          first_name: "Luc",
          last_name: "Stone",
          email: "lucstone@bm.com",
          avatar: "https://rb.gy/d7hxp",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 2,
            company_name: "B&M",
            email: "management@bm.net",
            logo: require("@/assets/temp/orgs/bm.png"),
          },
        },
      ],

      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 3,
      agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      squads: ["OP2", "Structures", "Product"],
      users: [
        {
          id: 5,
          first_name: "Mark",
          last_name: "Green",
          email: "mgreen@brooks.com",
          avatar: require("@/assets/temp/users/mark_green.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 3,
            company_name: "Brooks & Sons",
            email: "support@brooks.com",
            logo: require("@/assets/temp/orgs/bs.png"),
          },
        },
        {
          id: 31,
          first_name: "Bryan",
          last_name: "Brown",
          email: "bbrown@brooks.com",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Coordinator" },
          company: {
            id: 3,
            company_name: "Brooks & Sons",
            email: "support@brooks.com",
            logo: require("@/assets/temp/orgs/bs.png"),
          },
        },
        {
          id: 32,
          first_name: "Robert",
          last_name: "Wilson",
          email: "rwilson@brooks.com",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 3,
            company_name: "Brooks & Sons",
            email: "support@brooks.com",
            logo: require("@/assets/temp/orgs/bs.png"),
          },
        },
        {
          id: 33,
          first_name: "Benedita",
          last_name: "Nogueira",
          email: "bnogueira@brooks.com",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 3,
            company_name: "Brooks & Sons",
            email: "support@brooks.com",
            logo: require("@/assets/temp/orgs/bs.png"),
          },
        },
        {
          id: 34,
          first_name: "Nelson",
          last_name: "Amaral",
          email: "namaral@brooks.com",
          avatar: "",
          in_stages: [4], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 3,
            company_name: "Brooks & Sons",
            email: "support@brooks.com",
            logo: require("@/assets/temp/orgs/bs.png"),
          },
        },
        {
          id: 35,
          first_name: "Paul",
          last_name: "Morgan",
          email: "namaral@brooks.com",
          avatar: "",
          in_stages: [4], //frame_stage_id's
          role: { title: "Guest" },
          company: {
            id: 3,
            company_name: "Morgan Group",
            email: "support@brooks.com",
            logo: "",
          },
        },
      ],
      stages: [1, 2, 3, 4, 5, 6, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 4,
      agent: {
        id: 4,
        agent_name: "Client",
      },
      users: [
        {
          id: 10,
          first_name: "Mark",
          last_name: "Thompson",
          email: "mthompson@rsp.com",
          avatar: require("@/assets/temp/users/mark_thompson.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 4,
            company_name: "RS Prime",
            email: "general@rsp.com",
            logo: require("@/assets/temp/orgs/rs.png"),
          },
        },
        {
          id: 36,
          first_name: "Jose",
          last_name: "Miller",
          email: "jmiller@rs.com",
          avatar: "",
          in_stages: [1, 2, 3, 4], //frame_stage_id's
          role: { title: "Integrator" },
          company: {
            id: 4,
            company_name: "RS Prime",
            email: "general@rsp.com",
            logo: require("@/assets/temp/orgs/rs.png"),
          },
        },
        {
          id: 37,
          first_name: "Emma",
          last_name: "Johnson",
          email: "ejohnson@rs.com",
          avatar: "",
          in_stages: [1, 2, 3, 4],
          role: {
            title: "Integrator",
          },
          company: {
            id: 4,
            company_name: "RS Prime",
            email: "general@rsp.com",
            logo: require("@/assets/temp/orgs/rs.png"),
          },
        },
        {
          id: 38,
          first_name: "Olivia",
          last_name: "Brown",
          email: "obrown@rs.com",
          avatar: "",
          in_stages: [1, 2, 3, 4],
          role: {
            title: "Guest",
          },
          company: {
            id: 4,
            company_name: "RS Prime",
            email: "general@rsp.com",
            logo: require("@/assets/temp/orgs/rs.png"),
          },
        },
      ],
      stages: [1, 2, 3, 4, 5, 6, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 5,
      agent: {
        id: 4,
        agent_name: "Cost Consultant",
      },
      users: [
        {
          id: 11,
          first_name: "Jessica",
          last_name: "Smith",
          email: "jsmith@zafir.uk",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },

          company: {
            id: 2,
            company_name: "Zafir",
            email: "support@zafir.com",
            logo: require("@/assets/temp/orgs/zafir.png"),
          },
        },
        {
          id: 40,
          first_name: "Sophia",
          last_name: "Johnson",
          email: "sjohnson@zafir.uk",
          avatar: "",
          in_stages: [4, 5],
          role: {
            title: "Coordinator",
          },
          company: {
            id: 2,
            company_name: "Zafir",
            email: "support@zafir.com",
            logo: require("@/assets/temp/orgs/zafir.png"),
          },
        },
        {
          id: 41,
          first_name: "Ethan",
          last_name: "Williams",
          email: "ewilliams@zafir.uk",
          avatar: "",
          in_stages: [4, 5],
          role: {
            title: "Integrator",
          },
          company: {
            id: 2,
            company_name: "Zafir",
            email: "support@zafir.com",
            logo: require("@/assets/temp/orgs/zafir.png"),
          },
        },
      ],
      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 6,
      agent: {
        id: 4,
        agent_name: "Lead Designer",
      },
      users: [
        {
          id: 12,
          first_name: "Leighton",
          last_name: "Green",
          email: "lgreen@norcraft.net",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 5,
            company_name: "Norcraft",
            email: "general@nocraft.net",
            logo: require("@/assets/temp/orgs/norcraft.png"),
          },
        },
      ],

      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      id: 7,
      agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      users: [
        {
          id: 13,
          first_name: "João",
          last_name: "Ribeiro",
          email: "jribeiro@bimms.net",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 100,
            company_name: "Bimms",
            email: "support@bimms.net",
            logo: require("@/assets/temp/orgs/bimms.png"),
          },
        },
      ],

      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },

    {
      agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      users: [
        {
          id: 14,
          first_name: "Alan",
          last_name: "Davis",
          email: "adavis@tns.uk",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 6,
            company_name: "TNS",
            email: "support@tns.com",
            logo: require("@/assets/temp/orgs/tns.png"),
          },
        },
        {
          id: 42,
          first_name: "Sophie",
          last_name: "Wilson",
          email: "swilson@tns.uk",
          avatar: "",
          in_stages: [4, 5],
          role: {
            title: "Coordinator",
          },
          company: {
            id: 6,
            company_name: "TNS",
            email: "support@tns.com",
            logo: require("@/assets/temp/orgs/tns.png"),
          },
        },
        {
          id: 43,
          first_name: "Liam",
          last_name: "Jackson",
          email: "ljackson@tns.uk",
          avatar: "",
          in_stages: [4, 5],
          role: {
            title: "Integrator",
          },
          company: {
            id: 6,
            company_name: "TNS",
            email: "support@tns.com",
            logo: require("@/assets/temp/orgs/tns.png"),
          },
        },
      ],

      stages: [1, 2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      users: [
        {
          id: 15,
          first_name: "Ralph",
          last_name: "Ward",
          email: "rward@siterra.uk",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 7,
            company_name: "Siterra",
            email: "general@siterra.com",
            logo: require("@/assets/temp/orgs/siterra.png"),
          },
        },
      ],

      stages: [2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      users: [
        {
          id: 16,
          first_name: "Robert",
          last_name: "Hunt",
          email: "rhunt@dandf.net",
          avatar: require("@/assets/temp/users/robert_hunt.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 8,
            company_name: "D&F",
            email: "support@dnf.com",
            logo: require("@/assets/temp/orgs/df.png"),
          },
        },
      ],

      stages: [2, 5, 6, 7],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      users: [
        {
          id: 17,
          first_name: "Seth",
          last_name: "Hopkins",
          email: "shopkins@bsafety.com",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 9,
            company_name: "BSafety",
            email: "general@bsafety.com",
            logo: require("@/assets/temp/orgs/bsafe.png"),
          },
        },
      ],

      stages: [2, 3, 4, 5, 6, 7, 8],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      agent: {
        id: 12,
        agent_name: "Landscape Architect",
      },
      users: [
        {
          id: 18,
          first_name: "Elizabeth",
          last_name: "Fox",
          email: "efox@gradient.net",
          avatar: "",
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 10,
            company_name: "Gradient",
            email: "support@gradient.com",
            logo: require("@/assets/temp/orgs/gradient.png"),
          },
        },
      ],

      stages: [4],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
    {
      agent: {
        id: 13,
        agent_name: "Lift Engineer",
      },
      users: [
        {
          id: 19,
          first_name: "Harris",
          last_name: "Marshall",
          email: "hmarshall@niteroica.uk",
          avatar: require("@/assets/temp/users/harris_marshall.png"),
          in_stages: [4, 5], //frame_stage_id's
          role: { title: "Lead" },
          company: {
            id: 11,
            company_name: "Niteroica",
            email: "helpdesk@niteroica.com",
            logo: require("@/assets/temp/orgs/niteroica.png"),
          },
        },
      ],

      stages: [4],
      contracted_by: {
        id: 100,
        company_name: "Bimms",
        email: "general@bimms.net",
        logo: require("@/assets/temp/orgs/bimms.png"),
      },
    },
  ];
};
